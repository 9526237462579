export default {
    menu:{
        about:"关于我们",
        customize:"定制开发",
        website:"网站开发",
        app:"APP开发",
        wechat:"微信开发",
        store:"网上商城",
        it:"IT支援服务",
        news:"公司动态",
        contact:"联系我们",
        quotation:"获取报价",
        consultation:"立即咨询",
        service:"在线客服",
    },
    index:{
        welcome:"欢迎",
        come:"来到",
        name:"中天动力系统(深圳)有限公司",
        more:"深入了解",
        customize0:"订制开发您独一无二的系统",
        customize1:"固定资产系统",
        customize2:"贷款系统",
        customize3:"QC验货系统",
        customize4:"补习社系统",
        customize5:"批发系统",
        customize6:"CRM系统",
        customize7:"迷你仓系统",
        customize8:"网上商城",
        customize9:"ERP系统",
        customize10:"预约系统",
        customize11:"排队系统",
        customize12:"MRP系统",
        customize13:"贸易系统",
        customize14:"仓存系统",
        customize15:"OA系统",
        customize16:"装修平台",
        customize17:"其它",
        website1:"网站定制开发",
        website2:"网站定制开发是根据客户需求打造独一无二的网站解决方案，提供个性化、灵活性和可扩展性",
        app0:"根据客户需求定制开发独特的APP",
        app1:"预约系统APP",
        app2:"仓存APP",
        app3:"补习社APP",
        app4:"MRP APP",
        app5:"迷你仓APP",
        app6:"CRM APP",
        app7:"会员APP",
        app8:"OA APP",
        app9:"商城APP ",
        wechat1:"微信定制开发",
        wechat2:"微信开发，创造无限可能！公众号、小程序、企业微信，提供个性化互动和业务扩展",
        mall1:"多国货币",
        mall2:"多种语言",
        mall3:"赠劵折扣功能",
        mall4:"购物车",
        mall5:"商品搜寻",
        mall6:"线上付款功能",
        mall7:"会员系统",
        mall8:"翻查购买纪录",
        it1:"我们是领先业界的IT支援服务提供商，为您提供全方位的技术支持和解决方案。",
        it2:"我们的专业团队会根据您的需求，提供个人化的IT支援服务，确保您的系统和设备的顺利运作。",
        it3:"无论是即时支援还是定期维护，我们都致力于为您提供无忧的IT体验，让您专注于业务发展。",
    },
    footer:{
        company1:"中天科技系统有限公司",
        company2:"中天动力系统(深圳)有限公司",
        address:"地址",
        address1:"荃湾海盛路11号One Midtown 15楼13A室",
        address2:"深圳市罗湖区江背路8号庐山大厦C座17A11室",
        mail:"电邮",
        tel1:"(852) 24902300",
        tel2:"(0755) 25100512",
        mapName:"中天动力",
        map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.5385480304976!2d114.10573571194607!3d22.55895247941599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f5af5a069bd1%3A0xf2baf3039846bf58!2z77yo77yh77ys77yv5bm_5Zy65YGc6L2m5Zy6!5e0!3m2!1szh-CN!2s!4v1693462667791!5m2!1szh-CN!2s"
    },
    form:{
        placeholderName: '请输入姓名',
        placeholderTel: '请输入联络电话',
        placeholderEmail: '请输入电邮地址',
        placeholderText: '请输入留言',
        placeholderEmail2: '请输入正确电邮地址',
        name: "姓名：",
        mail: "电邮：",
        tel: "电话：",
        message: "留言：",
        submit: "提交",
        successText: '验证通过！ ',
        failText: '验证失败，请重试！ ',
        sliderText: "向右滑动",
        titles: "留言",
        titleText:"如果您有任何疑问，请联络我们，我们很高兴能为您解答疑问",
        tips:"星号为必填项",
        online:"租用网上商城"
    },
    project: {
        caseTit: '我们的案例',
        JiaZaiGD: '查看更多',
        ChaKan: '查看'
    },
    page:{
        quotation: "免费即时报价",
        customizeName0: "系统开发",
        customizeName1: "产品开发",
        customizeIntro1: "中天科技是一站式的电脑程式设计外包服务商:需求分析、定制解决方案、体系结构、UI/UX设计、开发、测试、部署、维护、系统管理和支持",
        customizeName2: "专业团队",
        customizeIntro2: "我们会反复检查您的目标及需求，并为您提供最适合实施方案",
        customizeName3: "现有系统支持及升级",
        customizeIntro3: "我们提供将现有系统支持及升级到新兴技术，同时亦保留原有的数据",
        customizeName4: "除错服务",
        customizeIntro4: "除我们提供远端除错服务，不管相隔多远，我们的团队都会像在你身旁与你沟通",
        customizeName5: "支持与维护",
        customizeIntro5: "客户可以通过电话或电子邮件跟我们的软件工程师沟通，我们的软件工程师会作出最快的响应，并修复代码中的错误",
        customizeName6: "代码审查服务",
        customizeIntro6: "将彻底检查现有产品的代码，以确保其可靠性、可维护性、效率和安全性方面符合最佳状况无论您的企业规模如何，我们都能够提供高效的IT支持和解决方案，针对您的具体需求定制服务。立即联系我们，获取更多信息，并获得有关我们的Help Desk和其他IT服务的免费报价。让我们帮助您摆脱IT困扰，专注于核心业务的发展",
        customizeTitle: "度身订制专属于您的系统",
        customizeName7: "贷款系统",
        customizeName71: "客户资料模组",
        customizeName72: "贷款管理模组",
        customizeName73: "还款管理模组",
        customizeName74: "客户资料模组",
        customizeName8: "贸易系统",
        customizeName81: "业务模组",
        customizeName82: "采购模组",
        customizeName83: "库存模组",
        customizeName84: "财务模组",
        customizeName9: "MRP生产管理系统",
        customizeName91: "销售模组",
        customizeName92: "生产模组",
        customizeName93: "采购模组",
        customizeName94: "质检模组",
        customizeName95: "仓存模组",
        customizeName10: "批发系统",
        customizeName101: "设置零售商",
        customizeName102: "设置各种水果名称及价钱",
        customizeName103: "生成订单",
        customizeName104: "整合总订单数量",
        customizeName105: "生成出货单及帐单",
        customizeName11: "装修平台",
        customizeName111: "用户注册及登入",
        customizeName112: "设置可提供之服务",
        customizeName113: "师傅注册及登入",
        customizeName114: "管理员审批师傅",
        customizeName115: "用户下单",
        customizeName116: "师傅接单",
        customizeName117: "完工后付款及用户对师傅评价",
        customizeName12: "固定资产系统",
        customizeName121: "用户注册及登入",
        customizeName122: "设置可提供之服务",
        customizeName123: "师傅注册及登入",
        customizeName124: "管理员审批师傅",
        customizeName125: "用户下单",
        customizeName126: "师傅接单",
        customizeName127: "完工后付款及用户对师傅评价",
        customizeName13: "补习社系统",
        customizeName131: "用户注册及登入",
        customizeName132: "设置免费试用期",
        customizeName133: "分中、小学生制",
        customizeName134: "小学生设有每日上传家课册功能",
        customizeName135: "即问即答功能–线上问功课",
        customizeName136: "不同科目之补充练习发放",
        customizeName137: "购买会员及续费服务",
        customizeName14: "迷你仓系统",
        customizeName141: "线上付款",
        customizeName142: "用户下单及预约送箱",
        customizeName143: "用户拍照物件",
        customizeName144: "设置每种不同收费",
        customizeName145: "线管理员清楚看到每张订单的详细资料上付款",
        customizeName146: "送箱人员收到送箱订单及联络人资料",
        customizeName147: "系统每日提示到期客户订单",
        customizeName148: "用户注册及登入",
        customizeName15: "迷你仓系统",
        customizeName151: "开报价单、保留不同版本报价单",
        customizeName152: "开Invoice及保存客户确认之报价单",
        customizeName153: "生成应收应付帐",
        customizeName154: "员工上班打卡，每月计算工资",
        customizeName155: "请假申请，看到自己的剩余年假及审批状态",
        customizeName156: "报销申请，看到审批状态",
        customizeName16: "QC验货系统",
        customizeName161: "从SAP系统中抽取所需要的验货单资料导入到APP中",
        customizeName162: "管理员在APP把验货单分配到相关的验货员帐号",
        customizeName163: "验货员登入可看到各自需负责的验货单资料、验货日期及工厂地址等资料",
        customizeName164: "验货员验货拍照及填写验货资料，验货后工厂负责人可在APP中签名，系统生成验货报告",
        customizeName165: "用户可将验货报告发送email",
        quotationNeed: "获取报价",
        bottom:"迅速报价，优质方案",
        website1:"响应式网站和传统网站对比",
        website2:"响应式网站和传统网站是两种不同的网站设计方法，它们在设计和使用上有一些重要的区别。响应式网站则采用了弹性布局，能够根据访问设备的屏幕尺寸和分辨率自动调整网站的外观和布局。",
        websiteName3:"响应式网站",
        websiteTxt311:"设计方式：",
        websiteTxt312:"响应式网站则采用了弹性布局，能够根据访问设备的屏幕尺寸和解析度自动调整网站的外观和布局。",
        websiteTxt321:"使用体验：",
        websiteTxt322:"响应式网站能够提供更好的使用体验，因为它能够适应各种设备，包括桌面电脑、平板电脑和手机等。不论用户使用何种设备访问网站，都能获得良好的浏览体验。",
        websiteTxt331:"开发成本：",
        websiteTxt332:"响应式网站通常需要额外的开发工作，因为它需要根据不同的屏幕尺寸和解析度调整布局和设计。这意味着开发响应式网站可能需要更多的时间和资源。",
        websiteTxt341:"SEO 优化：",
        websiteTxt342:"由于响应式网站提供了一个统一的 URL 和 HTML 代码，对于搜索引擎优化（SEO）来说更为有利。",
        websiteName4:"传统网站",
        websiteTxt41:"传统网站通常是针对桌面电脑的设计，使用固定尺寸和布局。",
        websiteTxt42:"传统网站在较小的屏幕上可能需要水平滚动或缩放以便查看内容，使用体验不如响应式网站便捷。",
        websiteTxt43:"传统网站只需要设计一个固定的版本，相对来说较为简单。",
        websiteTxt44:"传统网站如果有多个版本（例如桌面版和移动版），可能需要额外的 SEO 努力来确保每个版本都能获得良好的排名。",
        websiteName5:"多终端显示的优势",
        websiteTxt5:"提供良好的跨设备使用体验。提高网站的可访问性。增加用户参与度和互动性。有利于搜索引擎优化。",
        appName1:"原生APP",
        appTxt1:"原生APP是专为特定平台（例如iOS、Android）使用原生程式语言（例如Objective-C/Swift、Java/Kotlin）开发的应用程式。它们能够完全利用平台的功能和性能，提供最佳的使用者体验。原生APP通常需要单独开发和维护，并且需要分别针对不同平台进行开发。总的来说，原生APP提供最佳的使用者体验，但需要单独针对不同平台进行开发。",
        appName2:"混合APP",
        appTxt2:"混合APP是使用Web技术（HTML、CSS、JavaScript）开发的应用程式，然后使用框架（如React Native、Ionic）将其封装为原生应用程式。混合APP可以跨平台运行，并且可以重用部分程式码，从而节省开发成本和时间。然而，它们可能无法完全拥有原生APP的性能和功能。总的来说，混合APP具有跨平台的优势，但可能欠缺原生APP的性能。",
        appName3:"Web APP",
        appTxt3:"Web APP是使用Web技术（HTML、CSS、JavaScript）开发的应用程式，可以在网页浏览器中运行。Web APP不需要进行安装，用户可以通过浏览器直接访问。它们具有跨平台性和即时更新的优势，但可能受到网络连接和浏览器功能的限制。总的来说，Web APP具有跨平台和即时更新的优势，但在功能和性能方面可能受到限制。",
        wechatName1:"微信小程序",
        wechatTxt1:"微信小程序是在微信平台上运行的轻量级应用程式，用户可以在微信中直接使用，不需要额外的安装。它们提供了各种功能，包括线上购物、社交媒体、生活服务等。微信小程序开发使用的是小程序专用框架（如微信原生框架、Taro、uni-app），可以实现跨平台运行，并且具有较好的用户体验和性能。总的来说，微信小程序是在微信平台上运行的轻量级应用程式。",
        wechatName2:"微信公众号",
        wechatTxt2:"微信公众号是一种基于微信平台的官方帐号，用户可以订阅并接收公众号发布的讯息、文章、图片、视频等。它们通常由企业、组织、名人等拥有和运营，用于品牌推广、信息传递、客户互动等目的。微信公众号可以分为订阅号和服务号两种类型，功能和权限有所区别。总的来说，微信公众号是官方帐号用于信息传递和品牌推广。",
        wechatName3:"企业微信",
        wechatTxt3:"企业微信是针对企业内部使用的企业通讯和协作平台，类似于企业版的微信。它提供了企业内部通讯、组织架构、工作流程、应用程式集成等功能，用于加强企业内部沟通和协作。企业微信还支持企业应用程式的开发和定制，使企业能够根据自身需求扩展功能。总的来说，企业微信则是企业内部通讯和协作平台。",
        mall1:"探索无界购物体验：网上商城带您进入全新电子商务世界",
        mall111:"商品管理",
        mall2:"购物车功能",
        mall3:"收藏",
        mall4:"营销活动",
        mall5:"订单管理",
        mall6:"登录/注册",
        mall7:"会员中心",
        mall8:"售后退款",
        mall9:"租用限时优惠",
        mall10:"网站+APP商城（iOS+Android）",
        mall11:"年",
        mall12:"购买",
        mall13:"购买包含",
        mall14:"3种语言版本（繁、简、英）",
        mall15:"含一年云端空间10G空间及每月50G流量",
        mall16:"优惠价-第二年开始收取HKD 2,900.00元（云端空间及维护费用-含同样的服务即10G空间及每月50G流量，系统除错服务及app版本更新)",
        mall17:"第三年开始取正价HKD 5,800.00元（云端空间及维护费用-含同样的服务即10G空间及每月50G流量，系统除错服务及app版本更新)",
        mall19:"只接入最多4种支付方式，多于4种会额外收取费用",
        it0:require('@/assets/img/IT_pic01-cn.png'),
        it1:"服务范围覆盖中港两地",
        itName1:"资料备份和保护",
        itTxt1:"资料备份和保护是将数据复制到另一个位置或储存媒体，以防止数据丢失、损坏或未授权访问。它包括定期备份数据、制定备份策略、加密数据、监控和测试备份系统的有效性。这些措施确保数据的可靠性和安全性，并提供应对数据灾害的准备。",
        itName2:"桌面维护",
        itTxt2:"桌面维护是管理和支持计算机桌面环境的活动，包括系统监控、软件安装、病毒防护、数据备份和用户支持。它旨在提供稳定、安全和高效的桌面工作环境，确保用户顺利运作和数据安全。",
        itName3:"电脑技术支援",
        itTxt3:"电脑技术支援是提供解决电脑问题和技术支持的服务，包括软件安装、故障排除、病毒防护、网络连接、硬件维修和用户支持等。",
        itTxt31:"通常，电脑技术支援可以通过多种方式提供，包括远程支援、电话支援、在场支援和线上资源。",
        itTxt32:"简而言之，电脑技术支援是提供解决电脑和技术问题的服务，帮助用户顺利使用并维护他们的系统。它是确保顺利运行并充分利用电脑技术的关键一环。",
        itName4:"IT维护",
        itTxt4:"IT维护是指对信息技术（IT）基础设施和系统进行管理、保养和支持的一系列活动。它旨在确保IT资源的稳定运行，提供高效、安全和可靠的IT环境。",
        itTxt41:"目标是确保IT系统和资源的正常运行，最大程度地减少系统故障和数据损失的风险，提高组织的生产力和效率。它是企业和组织中IT部门的重要职能之一，有助于确保IT资源的可用性、安全性和可靠性。",
        itName5:"网络管理",
        itTxt5:"网络管理是指对计算机网络基础设施进行有效管理和监控的活动。它涵盖了网络设备、连接、资源和安全性等方面的管理。",
        itTxt51:"主要目标是确保网络的可用性、性能和安全性，以满足组织的需求并支持业务运作。",
        itTxt52:"确保网络运作顺利、安全可靠的关键一环。它有助于提高网络的效能、降低故障风险，并提供良好的用户体验。组织和企业可以借助网络管理来管理和维护他们的网络基础设施，以支持业务的顺利运作。",
        itName6:"伺服器管理",
        itTxt6:"伺服器管理是指对伺服器硬体和软件进行有效管理和监控的一系列活动。它包括伺服器的配置、安装、维护和监测，以确保伺服器的正常运行和高效性能。",
        itTxt61:"主要目标是确保伺服器的可用性、安全性和效能，以满足组织的需求并支持业务运作。",
        itTxt62:"确保伺服器运行顺利、安全可靠的重要一环。它有助于提高伺服器的效能、减少故障风险，并确保资料的完整性和可用性。组织和企业可以通过有效的伺服器管理，确保其业务系统的稳定运行，并满足不断变化的需求。",
        itReason:"选择CHINA SKY的理由",
        itReason1:"专业技术：拥有丰富的IT技术知识和经验。",
        itReason2:"个人化服务：提供量身定制的解决方案，关注客户的独特需求。",
        itReason3:"及时响应：快速响应并提供解决方案，减少停工时间。",
        itReason4:"综合解决方案：提供全方位的IT支援服务，节省时间和精力。",
        itReason5:"信任和信誉：拥有良好的信誉和口碑，与众多客户建立长期合作关系。",
        rentBtn: "租用",
        //
        look: "查看案例",
        addStore01:"中天动力系统周年庆",
        addStore02:"网上商城租赁",
        addStore03:"惊爆价！！",
        addStore04:"RMB",
        addStore05:"年",
        addStore06:"包含网站+APP商城（iOS+Android)",
        addStore07:"原价：RMB 21,800.00/年",
        addStore08:"立即租用",
        addStore09:"网上商城租赁服务",
        addStore10:"我们的网上商城租赁服务，为您免去昂贵的自主开发费用，节约您的开发时间，让您的业务尽快开展，服务包含网站与App，各类商品类型均可通用，一站式打造您的网上商城。",
        addStore11:"会员系统",
        addStore12:"商品系统",
        addStore13:"购物车",
        addStore14:"订单系统",
        addStore15:"在线支付",
        addStore16:"报表导出功能",
        addStore17:"折扣优惠功能",
        addStore18:"积分抵扣现金功能",
        addStore19:"支持繁、简、英三种语言",
        addStore20:"一年云端空间10GB,每月50G流量",
        addStore21:require('@/assets/img/ad-cn.jpg'),
    },
    video:{
        url:require('@/assets/img/video-cn.mp4'),
        subtitles1:"现今网络世界的进化速度惊人,为了让客户感到更亲切和方便，企业必须拥有完善的IT系统和网络平台。",
        subtitles2:"中天科技系统有限公司，于2004年在香港成立，同年于深圳开设分公司。",
        subtitles3:"我们一直为香港和内地的企业提供IT外包服务：包括固定检查、网络管理、网络安全、VPN解决方案等。",
        subtitles4:"由于我们于中港均有专业的技术人员，所以不论客户于香港或内地遇上IT问题都可以提供最即时和合适的支援。",
        subtitles5:"近年网络行销为企业提供了全新的商业机会，社交平台的相关程式和服务成为了各企业强大的工具。",
        subtitles6:"中天科技对每个项目由设计，开发及线上推广，都掌握了全面相关的技术，更拥有强大的开发和市场推广团队，为客户提供各类型的市场策略和对应的开发服务，用最合理的成本获得更大的回报。",
        subtitles7:"为配合大湾区和一带一路发展，无论国内和国外的企业都需要进一步优化生意流程和成本，拓展商机。",
        subtitles8:"我们拥有中国及国外的网络顾问经验，能为客户设计最合适的网络营销解决方案，",
        subtitles9:"让客户的业务获得更大的成功。",
    },
    net:{
        servesBigTitle0:"我们的服务",
        servesBigTitle1:"网络维护",
        servesTitle1:"网络监控",
        servesContent1:"监控网络流量、连接状态和效能，以确保网络运作正常。",
        servesTitle2:"安全性检查",
        servesContent2:"定期检查网络安全性，包括防火墙设定、入侵检测系统和安全漏洞，以确保网络不受到未授权访问或攻击。",
        servesTitle3:"数据备份和恢复",
        servesContent3:"建立定期的网络数据备份计划，确保重要数据能够在需要时快速恢复。",
        servesTitle4:"网络设备更新",
        servesContent4:"定期更新路由器、交换机和其他网络设备的软件，并检查硬件性能，以确保网络的高效运作。",
        servesTitle5:"网络性能优化",
        servesContent5:"优化网络设置，调整路由器和交换机的配置，以确保高效的数据传输和低延迟。",
        servesTitle6:"用户帐户管理",
        servesContent6:"管理用户帐户，包括创建、删除和修改账户权限，确保只有授权用户能够访问网络资源。",
        servesTitle7:"紧急应对计划",
        servesContent7:"制定应对网络故障和安全事件的计划，确保在网络问题发生时能够迅速应对和修复。",
        servesTitle8:"培训和意识提升",
        servesContent8:"为用户提供安全培训，提高他们的安全意识，降低网络威胁的风险。",
        servesBigTitle2:"计算机维护",
        servesTitle21:"硬件检测",
        servesContent21:"定期检查计算机硬件组件，确保良好的散热和运作效能。",
        servesTitle22:"软件更新和安全补丁",
        servesContent22:"安装操作系统、应用程序和防病毒软件的最新版本，同时确保所有安全补丁都被及时安装。",
        servesTitle23:"病毒和恶意软件扫描",
        servesContent23:"定期进行病毒和恶意软件扫描，以确保系统的安全性，并清除任何潜在的威胁。",
        servesTitle24:"数据备份和恢复",
        servesContent24:"建立定期的数据备份计划，确保重要数据能够在需要时快速恢复。",
        servesTitle25:"性能优化",
        servesContent25:"优化系统设置，提升计算机性能，并清除不必要的文件和应用程序，释放硬盘空间。",
        servesTitle26:"硬件更换和升级",
        servesContent26:"定期评估硬件性能，根据需求进行硬件升级或更换，以确保系统跟得上业务需求。",
        servesTitle27:"用户支持和问题解决",
        servesContent27:"提供用户支持服务，解决用户在使用计算机时遇到的问题，确保用户能够高效地使用系统。",
        reason0:"选择我们的原因",
        reason1:"计算机系统是企业运作的核心，包括数据储存、处理、通讯等功能。定期的维护可以确保系统稳定运行，减少故障发生的机会，确保业务连续性。",
        reason2:"网络安全问题日益严重，保养服务可以定期检测系统漏洞，安装最新的安全补丁，提高系统的抗攻击能力，保护重要数据不受侵害。",
        reason3:"定期的维护还可以延长计算机设备的寿命，降低企业的运营成本。总的来说，计算机维护及保养服务对于企业来说是一个非常重要且必要的投资。",
        set0:"服务套餐",
        set1:"远程服务",
        set2:"特急上门（按次）",
        set3:"月费服务",
        set4:"年费服务",
        set5:"远程",
        set6:"电话",
        set7:"到场",
        set8:"定期检查",
        set9:"无限",
        set10:"1次",
        set11:"1次/月",
    },

}


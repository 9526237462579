export default {
    menu:{
        about:"關於我們",
        customize:"定制開發",
        website:"網站開發",
        app:"APP開發",
        wechat:"微信開發",
        store:"網上商城",
        it:"IT支援服務",
        news:"公司动态",
        contact:"聯繫我們",
        quotation:"獲取報價",
    },
    index:{
        welcome:"歡迎",
        come:"來到",
        name:"中天科技系統有限公司",
        more:"深入了解",
        customize0:"訂製開發您獨一無二的系統",
        customize1:"固定資產系統",
        customize2:"貸款系統",
        customize3:"QC驗貨系統",
        customize4:"補習社系統",
        customize5:"批發系統",
        customize6:"CRM系統",
        customize7:"迷你倉系統",
        customize8:"網上商城",
        customize9:"ERP系統",
        customize10:"預約系統",
        customize11:"排隊系統",
        customize12:"MRP系統",
        customize13:"貿易系統",
        customize14:"倉存系統",
        customize15:"OA系統",
        customize16:"裝修平臺",
        customize17:"其它",
        website1:"網站定制開發",
        website2:"網站定制開發是根據客戶需求打造獨一無二的網站解決方案，提供個性化、靈活性和可擴展性",
        app0:"根據客戶需求定制開發獨特的APP",
        app1:"預約系統APP",
        app2:"倉存APP",
        app3:"補習社APP",
        app4:"MRP APP",
        app5:"迷你倉APP",
        app6:"CRM APP",
        app7:"會員APP",
        app8:"OA APP",
        app9:"商城APP ",
        wechat1:"微信定制開發",
        wechat2:"微信開發，創造無限可能！公眾號、小程序、企業微信，提供個性化互動和業務擴展",
        mall1:"多國貨幣",
        mall2:"多種語言",
        mall3:"贈劵折扣功能",
        mall4:"購物車",
        mall5:"商品搜尋",
        mall6:"線上付款功能",
        mall7:"會員系統",
        mall8:"翻查購買紀錄",
        it1:"我們是領先業界的IT支援服務提供商，為您提供全方位的技術支持和解決方案。",
        it2:"我們的專業團隊會根據您的需求，提供個人化的IT支援服務，確保您的系統和設備的順利運作。",
        it3:"無論是即時支援還是定期維護，我們都致力於為您提供無憂的IT體驗，讓您專注於業務發展。",
    },
    footer:{
        company1:"中天科技系統有限公司",
        company2:"中天動力系統(深圳)有限公司",
        address:"地址",
        address1:"荃灣海盛路11號One Midtown 15樓13A室",
        address2:"深圳市羅湖區江背路8號廬山大廈C座17A11室",
        mail:"電郵",
        // mail1:"info@chinaskynet.net",
        tel1:"(852) 24902300",
        tel2:"(0755) 25100512",
        map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.5936695248754!2d114.10767737979234!3d22.37254927203976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f8e5707631e1%3A0x2ef537658d4502e8!2sOne+Midtown%2C+11+Hoi+Shing+Rd%2C+Tsuen+Wan!5e0!3m2!1szh-CN!2shk!4v1547459323144"
        // copyright:"Copyright @ 2023 China Sky Technology System Limited. All rights reserved",
    },
    form:{
        placeholderName: '請輸入姓名',
        placeholderTel: '請輸入聯絡電話',
        placeholderEmail: '請輸入電郵地址',
        placeholderText: '請輸入留言',
        placeholderEmail2: '請輸入正確電郵地址',
        name: "姓名：",
        mail: "電郵：",
        tel: "電話：",
        message: "留言：",
        submit: "提交",
        successText: '驗證通過！',
        failText: '驗證失敗，請重試！',
        sliderText: "向右滑動",
        titles: "留言",
        titleText:"如果您有任何疑問，請聯絡我們，我們很高興能為您解答疑問",
        tips:"星號為必填項",
        online:"租用網上商城"
    },
    project: {
        caseTit: '我們的案例',
        JiaZaiGD: '查看更多',
        ChaKan: '查看'
    },
    page:{
        quotation: "免費及時報價",
        customizeName0: "系統開發",
        customizeName1: "產品開發",
        customizeIntro1: "中天科技是一站式的電腦程式設計外包服務商:需求分析、定制解決方案、體系結構、UI/UX設計、開發、測試、部署、維護、系統管理和支持",
        customizeName2: "專業團隊",
        customizeIntro2: "我們會反復檢查您的目標及需求，並為您提供最適合實施方案",
        customizeName3: "現有系統支持及升級",
        customizeIntro3: "我們提供將現有系統支持及升級到新興技術，同時亦保留原有的數據",
        customizeName4: "除錯服務",
        customizeIntro4: "除我們提供遠端除錯服務，不管相隔多遠，我們的團隊都會像在你身旁與你溝通",
        customizeName5: "支持與維護",
        customizeIntro5: "客戶可以通過電話或電子郵件跟我們的軟件工程師溝通，我們的軟件工程師會作出最快的響應，並修復代碼中的錯誤",
        customizeName6: "代碼審查服務",
        customizeIntro6: "將徹底檢查現有產品的代碼，以確保其可靠性、可維護性、效率和安全性方面符合最佳狀況無論您的企業規模如何，我們都能夠提供高效的IT支持和解決方案，針對您的具體需求定制服務。立即聯繫我們，獲取更多信息，並獲得有關我們的Help Desk和其他IT服務的免費報價。讓我們幫助您擺脫IT困擾，專注於核心業務的發展",
        customizeTitle: "度身訂製專屬於您的系統",
        customizeName7: "貸款系統",
        customizeName71: "客戶資料模組",
        customizeName72: "貸款管理模組",
        customizeName73: "還款管理模組",
        customizeName74: "客戶資料模組",
        customizeName8: "貿易系統",
        customizeName81: "業務模組",
        customizeName82: "採購模組",
        customizeName83: "庫存模組",
        customizeName84: "財務模組",
        customizeName9: "MRP生產管理系統",
        customizeName91: "銷售模組",
        customizeName92: "生產模組",
        customizeName93: "採購模組",
        customizeName94: "質檢模組",
        customizeName95: "倉存模組",
        customizeName10: "批發系統",
        customizeName101: "設置零售商",
        customizeName102: "設置各種水果名稱及價錢",
        customizeName103: "生成訂單",
        customizeName104: "整合總訂單數量",
        customizeName105: "生成出貨單及帳單",
        customizeName11: "裝修平臺",
        customizeName111: "用戶註冊及登入",
        customizeName112: "設置可提供之服務",
        customizeName113: "師傅註冊及登入",
        customizeName114: "管理員審批師傅",
        customizeName115: "用戶下單",
        customizeName116: "師傅接單",
        customizeName117: "完工後付款及用戶對師傅評價",
        customizeName12: "固定資產系統",
        customizeName121: "用戶註冊及登入",
        customizeName122: "設置可提供之服務",
        customizeName123: "師傅註冊及登入",
        customizeName124: "管理員審批師傅",
        customizeName125: "用戶下單",
        customizeName126: "師傅接單",
        customizeName127: "完工後付款及用戶對師傅評價",
        customizeName13: "補習社系統",
        customizeName131: "用戶註冊及登入",
        customizeName132: "設置免費試用期",
        customizeName133: "分中、小學生制",
        customizeName134: "小學生設有每日上傳家課冊功能",
        customizeName135: "即問即答功能–線上問功課",
        customizeName136: "不同科目之補充練習發放",
        customizeName137: "購買會員及續費服務",
        customizeName14: "迷你倉系統",
        customizeName141: "線上付款",
        customizeName142: "用戶下單及預約送箱",
        customizeName143: "用戶拍照物件",
        customizeName144: "設置每種不同收費",
        customizeName145: "線管理員清楚看到每張訂單的詳細資料上付款",
        customizeName146: "送箱人員收到送箱訂單及聯絡人資料",
        customizeName147: "系統每日提示到期客戶訂單",
        customizeName148: "用戶註冊及登入",
        customizeName15: "迷你倉系統",
        customizeName151: "開報價單、保留不同版本報價單",
        customizeName152: "開Invoice及保存客戶確認之報價單",
        customizeName153: "生成應收應付帳",
        customizeName154: "員工上班打卡，每月計算工資",
        customizeName155: "請假申請，看到自己的剩餘年假及審批狀態",
        customizeName156: "報銷申請，看到審批狀態",
        customizeName16: "QC驗貨系統",
        customizeName161: "從SAP系統中抽取所需要的驗貨單資料導入到APP中",
        customizeName162: "管理員在APP把驗貨單分配到相關的驗貨員帳號",
        customizeName163: "驗貨員登入可看到各自需負責的驗貨單資料、驗貨日期及工廠地址等資料",
        customizeName164: "驗貨員驗貨拍照及填寫驗貨資料，驗貨後工廠負責人可在APP中簽名，系統生成驗貨報告",
        customizeName165: "用戶可將驗貨報告發送email",
        quotationNeed: "獲取報價",
        bottom:"迅速報價，優質方案",
        website1:"響應式網站和傳統網站對比",
        website2:"響應式網站和傳統網站是兩種不同的網站設計方法，它們在設計和使用上有一些重要的區別。下面是響應式網站和傳統網站的幾個對比點",
        websiteName3:"響應式網站",
        websiteTxt311:"設計方式：",
        websiteTxt312:"響應式網站則採用了彈性佈局，能夠根據訪問設備的屏幕尺寸和解析度自動調整網站的外觀和佈局。",
        websiteTxt321:"使用體驗：",
        websiteTxt322:"響應式網站能夠提供更好的使用體驗，因為它能夠適應各種設備，包括桌面電腦、平板電腦和手機等。不論用戶使用何種設備訪問網站，都能獲得良好的瀏覽體驗。",
        websiteTxt331:"開發成本：",
        websiteTxt332:"響應式網站通常需要額外的開發工作，因為它需要根據不同的屏幕尺寸和解析度調整佈局和設計。這意味著開發響應式網站可能需要更多的時間和資源。",
        websiteTxt341:"SEO 優化：",
        websiteTxt342:"由於響應式網站提供了一個統一的 URL 和 HTML 代碼，對於搜索引擎優化（SEO）來說更為有利。",
        websiteName4:"傳統網站",
        websiteTxt41:"傳統網站通常是針對桌面電腦的設計，使用固定尺寸和佈局。",
        websiteTxt42:"傳統網站在較小的屏幕上可能需要水平滾動或縮放以便查看內容，使用體驗不如響應式網站便捷。",
        websiteTxt43:"傳統網站只需要設計一個固定的版本，相對來說較為簡單。",
        websiteTxt44:"傳統網站如果有多個版本（例如桌面版和移動版），可能需要額外的 SEO 努力來確保每個版本都能獲得良好的排名。",
        websiteName5:"多終端顯示的優勢",
        websiteTxt5:"提供良好的跨設備使用體驗。提高網站的可訪問性。增加用戶參與度和互動性。有利於搜索引擎優化",
        appName1:"原生APP",
        appTxt1:"原生APP是專為特定平台（例如iOS、Android）使用原生程式語言（例如Objective-C/Swift、Java/Kotlin）開發的應用程式。它們能夠完全利用平台的功能和性能，提供最佳的使用者體驗。原生APP通常需要單獨開發和維護，並且需要分別針對不同平台進行開發。總的來說，原生APP提供最佳的使用者體驗，但需要單獨針對不同平台進行開發。",
        appName2:"混合APP",
        appTxt2:"混合APP是使用Web技術（HTML、CSS、JavaScript）開發的應用程式，然後使用框架（如React Native、Ionic）將其封裝為原生應用程式。混合APP可以跨平台運行，並且可以重用部分程式碼，從而節省開發成本和時間。然而，它們可能無法完全擁有原生APP的性能和功能。總的來說，混合APP具有跨平台的優勢，但可能欠缺原生APP的性能。",
        appName3:"Web APP",
        appTxt3:"Web APP是使用Web技術（HTML、CSS、JavaScript）開發的應用程式，可以在網頁瀏覽器中運行。Web APP不需要進行安裝，用戶可以通過瀏覽器直接訪問。它們具有跨平台性和即時更新的優勢，但可能受到網路連接和瀏覽器功能的限制。總的來說，Web APP具有跨平台和即時更新的優勢，但在功能和性能方面可能受到限制。",
        wechatName1:"微信小程序",
        wechatTxt1:"微信小程序是在微信平台上運行的輕量級應用程式，用戶可以在微信中直接使用，不需要額外的安裝。它們提供了各種功能，包括線上購物、社交媒體、生活服務等。微信小程序開發使用的是小程序專用框架（如微信原生框架、Taro、uni-app），可以實現跨平台運行，並且具有較好的用戶體驗和性能。總的來說，微信小程序是在微信平台上運行的輕量級應用程式。",
        wechatName2:"微信公眾號",
        wechatTxt2:"微信公眾號是一種基於微信平台的官方帳號，用戶可以訂閱並接收公眾號發布的訊息、文章、圖片、視頻等。它們通常由企業、組織、名人等擁有和運營，用於品牌推廣、信息傳遞、客戶互動等目的。微信公眾號可以分為訂閱号和服務号兩種類型，功能和權限有所區別。總的來說，微信公眾號是官方帳號用於信息傳遞和品牌推廣。",
        wechatName3:"企業微信",
        wechatTxt3:"企業微信是針對企業內部使用的企業通訊和協作平台，類似於企業版的微信。它提供了企業內部通訊、組織架構、工作流程、應用程式集成等功能，用於加強企業內部溝通和協作。企業微信還支持企業應用程式的開發和定制，使企業能夠根據自身需求擴展功能。總的來說，企業微信則是企業內部通訊和協作平台。",
        mall1:"探索無界購物體驗：網上商城帶您進入全新電子商務世界",
        mall111:"商品管理",
        mall2:"購物車功能",
        mall3:"收藏",
        mall4:"營銷活動",
        mall5:"訂單管理",
        mall6:"登錄/註冊",
        mall7:"會員中心",
        mall8:"售後退款",
        mall9:"租用限時優惠",
        mall10:"網站+APP商城（iOS+Android）",
        mall11:"年",
        mall12:"購買",
        mall13:"購買包含",
        mall14:"3種語言版本（繁、簡、英）",
        mall15:"含一年雲端空間10G空間及每月50G流量",
        mall16:"優惠價-第二年開始收取HKD 2,900.00元（雲端空間及維護費用-含同樣的服務即10G空間及每月50G流量，系統除錯服務及app版本更新)",
        mall17:"第三年開始取正價HKD 5,800.00元（雲端空間及維護費用-含同樣的服務即10G空間及每月50G流量，系統除錯服務及app版本更新)",
        mall19:"只接入最多4種支付方式，多於4種會額外收取費用",
        it0:require('@/assets/img/IT_pic01-hk.png'),
        it1:"服務範圍覆蓋中港兩地",
        itName1:"資料備份和保護",
        itTxt1:"資料備份和保護是將數據複製到另一個位置或儲存媒體，以防止數據丟失、損壞或未授權訪問。它包括定期備份數據、制定備份策略、加密數據、監控和測試備份系統的有效性。這些措施確保數據的可靠性和安全性，並提供應對數據災害的準備。",
        itName2:"桌面維護",
        itTxt2:"桌面維護是管理和支持計算機桌面環境的活動，包括系統監控、軟件安裝、病毒防護、數據備份和用戶支持。它旨在提供穩定、安全和高效的桌面工作環境，確保用戶順利運作和數據安全。",
        itName3:"電腦技術支援",
        itTxt3:"電腦技術支援是提供解決電腦問題和技術支持的服務，包括軟件安裝、故障排除、病毒防護、網絡連接、硬件維修和用戶支持等。",
        itTxt31:"通常，電腦技術支援可以通過多種方式提供，包括遠程支援、電話支援、在場支援和線上資源。",
        itTxt32:"簡而言之，電腦技術支援是提供解決電腦和技術問題的服務，幫助用戶順利使用並維護他們的系統。它是確保順利運行並充分利用電腦技術的關鍵一環。",
        itName4:"IT維護",
        itTxt4:"IT維護是指對信息技術（IT）基礎設施和系統進行管理、保養和支持的一系列活動。它旨在確保IT資源的穩定運行，提供高效、安全和可靠的IT環境。",
        itTxt41:"目標是確保IT系統和資源的正常運行，最大程度地減少系統故障和數據損失的風險，提高組織的生產力和效率。它是企業和組織中IT部門的重要職能之一，有助於確保IT資源的可用性、安全性和可靠性。",
        itName5:"網路管理",
        itTxt5:"網路管理是指對計算機網絡基礎設施進行有效管理和監控的活動。它涵蓋了網絡設備、連接、資源和安全性等方面的管理。",
        itTxt51:"主要目標是確保網絡的可用性、性能和安全性，以滿足組織的需求並支持業務運作。",
        itTxt52:"確保網絡運作順利、安全可靠的關鍵一環。它有助於提高網絡的效能、降低故障風險，並提供良好的用戶體驗。組織和企業可以借助網路管理來管理和維護他們的網絡基礎設施，以支持業務的順利運作。",
        itName6:"伺服器管理",
        itTxt6:"伺服器管理是指對伺服器硬體和軟體進行有效管理和監控的一系列活動。它包括伺服器的配置、安裝、維護和監測，以確保伺服器的正常運行和高效性能。",
        itTxt61:"主要目標是確保伺服器的可用性、安全性和效能，以滿足組織的需求並支持業務運作。",
        itTxt62:"確保伺服器運行順利、安全可靠的重要一環。它有助於提高伺服器的效能、減少故障風險，並確保資料的完整性和可用性。組織和企業可以通過有效的伺服器管理，確保其業務系統的穩定運行，並滿足不斷變化的需求。",
        itReason:"選擇CHINA SKY的理由",
        itReason1:"專業技術：擁有豐富的IT技術知識和經驗。",
        itReason2:"個人化服務：提供量身定制的解決方案，關注客戶的獨特需求。",
        itReason3:"及時響應：快速響應並提供解決方案，減少停工時間。",
        itReason4:"綜合解決方案：提供全方位的IT支援服務，節省時間和精力。",
        itReason5:"信任和信譽：擁有良好的信譽和口碑，與眾多客戶建立長期合作關係。",
        rentBtn: "租用",
    },
    video:{
        url:require('@/assets/img/video-hk.mp4'),
        subtitles1:"現今網絡世界的進化速度驚人,為了讓客戶感到更親切和方便，企業必須擁有完善的IT系統和網絡平台。",
        subtitles2:"中天科技系統有限公司，於2004年在香港成立，同年於深圳開設分公司。",
        subtitles3:"我們一直為香港和內地的企業提供IT外包服務：包括固定檢查、網路管理、網路安全、VPN解決方案等。",
        subtitles4:"由於我們於中港均有專業的技術人員，所以不論客戶於香港或內地遇上IT問題都可以提供最即時和合適的支援。",
        subtitles5:"近年網絡行銷為企業提供了全新的商業機會，社交平台的相關程式和服務成為了各企業強大的工具。",
        subtitles6:"中天科技對每個項目由設計，開發及線上推廣，都掌握了全面相關的技術，更擁有強大的開發和市場推廣團隊，為客戶提供各類型的市場策略和對應的開發服務，用最合理的成本獲得更大的回報。",
        subtitles7:"為配合大灣區和一帶一路發展，無論國內和國外的企業都需要進一步優化生意流程和成本，拓展商機。",
        subtitles8:"我們擁有中國及國外的網絡顧問經驗，能為客戶設計最合適的網絡營銷解決方案，",
        subtitles9:"讓客戶的業務獲得更大的成功。",
    },
    quote:{

    },



}

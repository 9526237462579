export default {
    menu:{
        about:"About Us",
        customize:"Custom Development",
        website:"Website Development",
        app:"APP Development",
        wechat:"WeChat Development",
        store:"Online Store",
        it:"IT Support Services",
        news:"News",
        contact:"Contact Us",
        quotation:"Get a Quote",
        consultation:"Consult now",
        service:"Online service",
    },
    index:{
        welcome:"Welcome",
        come:"to",
        name:"CHINA SKY TECHNOLOGY SYSTEM LIMITED",
        more:"More Detail",
        customize0:"Customize and develop your unique system",
        customize1:"Fixed Assets System",
        customize2:"Loan System",
        customize3:"QC Inspection System",
        customize4:"Tuition Center System",
        customize5:"Wholesale System",
        customize6:"CRM System",
        customize7:"Mini Storage System",
        customize8:"Online Store",
        customize9:"ERP System",
        customize10:"Appointment Booking System",
        customize11:"Queue System",
        customize12:"MRP System",
        customize13:"Trade System",
        customize14:"Inventory System",
        customize15:"OA System",
        customize16:"Renovation Platform",
        customize17:"Other",
        website1:"Website Development",
        website2:"Custom Website Development involves creating a unique website solution that is personalized, flexible, and scalable to meet the client's specific requirements.",
        app0:"Custom App Development involves creating a unique app tailored to meet the specific requirements of the client.",
        app1:"Appointment Booking App",
        app2:"Warehouse APP",
        app3:"Tutoring Center APP",
        app4:"MRP APP",
        app5:"Mini Storage APP",
        app6:"CRM APP",
        app7:"Membership APP",
        app8:"OA APP",
        app9:"Online StoreAPP ",
        wechat1:"Custom WeChat Development",
        wechat2:"Unlock Unlimited Possibilities with WeChat! We offer personalized interactions and business expansion through WeChat Official Accounts, Mini Programs, and WeChat Work.",
        mall1:"Multi-Currency Support",
        mall2:"Multi-Language Support",
        mall3:"Gift Voucher and Discount Functionality",
        mall4:"Shopping Cart",
        mall5:"Product Search",
        mall6:"Online Payment Functionality",
        mall7:"Membership System",
        mall8:"Order History Lookup",
        it1:"We are a leading IT support service provider, offering comprehensive technical support and solutions for your needs.",
        it2:"Our team delivers personalized IT support services tailored to your needs, ensuring the smooth operation of your systems and devices.",
        it3:"We offer worry-free IT support and maintenance services, allowing you to focus on business development.",
},
footer:{
        company1:"CHINA SKY TECHNOLOGY SYSTEM LIMITED",
        company2:"CHINA SKY TECHNOLOGY SYSTEM (SZ) LIMITED",
        address:"Address",
        address1:"Room 13A, 15 Floor, One Midtown, No. 11 Hoi Shing Road, Tsuen Wan, Hong Kong",
        address2:"Room 17A11, Block C, Lushan Building, No. 8 Jiangbei Road, Luohu District, Shenzhen, China",
        mail:"E-mail",
        // mail1:"info@chinaskynet.net",
        tel1:"(852) 24902300",
        tel2:"(0755) 25100512",
        mapName:"CHINA SKY ",
        map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.5936695248754!2d114.10767737979234!3d22.37254927203976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f8e5707631e1%3A0x2ef537658d4502e8!2sOne+Midtown%2C+11+Hoi+Shing+Rd%2C+Tsuen+Wan!5e0!3m2!1szh-CN!2shk!4v1547459323144"
},
form:{
    placeholderName: 'Please enter your name',
        placeholderTel: 'Please enter your phone number',
        placeholderEmail: 'Please enter your email address',
        placeholderText: 'Please enter your message',
        placeholderEmail2: 'Please enter a valid email address.',
        name: "Name：",
        mail: "E-mail：",
        tel: "Phone：",
        message: "Message：",
        submit: "Submit",
        successText: 'Verification successful!',
        failText: 'Verification failed. Please retry.',
        sliderText: "To swipe right.",
        titles: "Message",
        titleText:"Have questions? Contact us for answers.",
        tips:"indicates required fields.",
        online:"Rent an online store"
},
project: {
        caseTit: 'Our Case Studies',
        JiaZaiGD: 'Show More',
        ChaKan: 'See More'
},
page:{
        quotation: "Free Real-Time Quote",
        customizeName0: "System Development",
        customizeName1: "Product Development",
        customizeIntro1: "China Sky Technology: Your one-stop solution for computer software design outsourcing. We provide comprehensive services from requirement analysis to system support and maintenance.",
        customizeName2: "Professional Team",
        customizeIntro2: "We carefully examine your goals and requirements, providing you with the most suitable implementation plan.",
        customizeName3: "Existing System Support and Upgrades",
        customizeIntro3: "We offer support and upgrades for existing systems, migrating them to emerging technologies while preserving the existing data.",
        customizeName4: "Debugging Services",
        customizeIntro4: "In addition to offering remote debugging services, our team will communicate with you as if we were right there with you, no matter the distance.",
        customizeName5: "Support and Maintenance",
        customizeIntro5: "Prompt communication with our software engineers via phone or email. Quick response and error fixing.",
        customizeName6: "Code Review Services",
        customizeIntro6: "Code review services ensure code reliability, maintainability, efficiency, and security. We offer tailored IT support and solutions for businesses of all sizes. Contact us for more information and a free quote on our Help Desk and other IT services. Let us help you overcome IT challenges and focus on your core business.",
        customizeTitle: "Customize a system tailored specifically to your needs.",
        customizeName7: "Loan System",
        customizeName71: "Customer Data Module",
        customizeName72: "Loan Management Module",
        customizeName73: "Repayment Management Module",
        customizeName74: "Customer Information Module",
        customizeName8: "Trade System",
        customizeName81: "Sales Module",
        customizeName82: "Procurement Module",
        customizeName83: "Inventory Module",
        customizeName84: "Finance Module",
        customizeName9: "MRP Production Management System",
        customizeName91: "Sales Module",
        customizeName92: "Production Module",
        customizeName93: "Procurement Module",
        customizeName94: "Quality Inspection Module",
        customizeName95: "Inventory Module",
        customizeName10: "Wholesale System",
        customizeName101: "Set up retailers",
        customizeName102: "Set up various fruit names and prices",
        customizeName103: "Generate orders",
        customizeName104: "Consolidate total order quantities",
        customizeName105: "Generate shipping orders and invoices",
        customizeName11: "Renovation Platform",
        customizeName111: "User Registration and Login",
        customizeName112: "Set Available Services",
        customizeName113: "Craftsman Registration and Login",
        customizeName114: "Administrator Approval of Craftsman",
        customizeName115: "User Placing Orders",
        customizeName116: "Craftsman Accepting Orders",
        customizeName117: "Payment after Completion and User Rating of Craftsman",
        customizeName12: "Fixed Assets System",
        customizeName121: "User Registration and Login",
        customizeName122: "Set Available Services",
        customizeName123: "Craftsman Registration and Login",
        customizeName124: "Administrator Approval of Craftsman",
        customizeName125: "User Placing Orders",
        customizeName126: "Craftsman Accepting Orders",
        customizeName127: "Payment after Completion and User Rating of Craftsman",
        customizeName13: "Tutoring Center System",
        customizeName131: "User Registration and Login",
        customizeName132: "Set Free Trial Period",
        customizeName133: "Division for Primary and Secondary School Students",
        customizeName134: "Primary School Students Can Upload Daily Homework",
        customizeName135: "Instant Q&A Function - Online Homework Help",
        customizeName136: "Provision of Supplementary Exercises in Various Subjects",
        customizeName137: "Membership Purchase and Renewal Service",
        customizeName14: "Self-Storage System",
        customizeName141: "Online Payment",
        customizeName142: "User Ordering and Reservation of Storage Units",
        customizeName143: "User Photographs Items",
        customizeName144: "Set Different Charges for Each Type",
        customizeName145: "Admin Can View Detailed Information of Each Order and Payment Status",
        customizeName146: "Delivery Personnel Receive Delivery Orders and Contact Information",
        customizeName147: "System Daily Reminder for Expiring Customer Orders",
        customizeName148: "User Registration and Login",
        customizeName15: "Mini Storage System",
        customizeName151: "Create Quotations and Save Different Versions of Quotations",
        customizeName152: "Generate Invoices and Save Confirmed Quotations from Customers",
        customizeName153: "Generate Accounts Receivable and Accounts Payable",
        customizeName154: "Employee Clock-in for Attendance and Monthly Salary Calculation",
        customizeName155: "Leave Application, View Remaining Annual Leave and Approval Status",
        customizeName156: "Expense Reimbursement Application, View Approval Status",
        customizeName16: "QC Inspection System",
        customizeName161: "Extract required inspection data from the SAP system and import it into the app",
        customizeName162: "Administrators assign inspection orders to relevant inspector accounts in the app",
        customizeName163: "Inspectors log in to view the inspection orders they are responsible for, including inspection dates and factory addresses",
        customizeName164: "Inspectors perform inspections, take photos, and enter inspection data. After inspection, factory representatives can sign in to the app, and the system generates an inspection report",
        customizeName165: "Users can send the inspection report via email",
        quotationNeed: "Get a Quote",
        bottom:"Fast Quote, Quality Solutions",
        website1:"Responsive Website vs. Traditional Website Comparison",
        website2:"Responsive websites and traditional websites are two different approaches to website design, and they have some significant differences in terms of design and usage. Responsive websites, on the other hand, utilize flexible layouts that automatically adjust the appearance and layout of the website based on the screen size and resolution of the accessing device.",
        websiteName3:"Responsive websites",
        websiteTxt311:"Design Approach：",
        websiteTxt312:"Responsive websites, on the other hand, utilize fluid layouts that can automatically adjust the appearance and layout of the website based on the screen size and resolution of the accessing device.",
        websiteTxt321:"User Experience：",
        websiteTxt322:"Responsive websites offer a better user experience as they can adapt to various devices, including desktop computers, tablets, and mobile phones. Regardless of the device users use to access the website, they can enjoy a seamless browsing experience.",
        websiteTxt331:"Development costs：",
        websiteTxt332:"Responsive websites typically require additional development work as they need to adjust layouts and designs based on different screen sizes and resolutions. This means that developing a responsive website may require more time and resources.",
        websiteTxt341:"SEO Benefits：",
        websiteTxt342:"Due to the provision of a unified URL and HTML code, responsive websites are more favorable for search engine optimization (SEO).",
        websiteName4:"Traditional websites",
        websiteTxt41:"Traditional websites are typically designed for desktop computers, using fixed sizes and layouts.",
        websiteTxt42:"Traditional websites may require horizontal scrolling or zooming on smaller screens to view content, resulting in a less convenient user experience compared to responsive websites.",
        websiteTxt43:"Traditional websites only require designing a single fixed version, which makes the development process relatively simpler compared to responsive websites.",
        websiteTxt44:"If a traditional website has multiple versions (such as desktop and mobile versions), additional SEO efforts may be required to ensure that each version achieves good rankings.",
        websiteName5:"Multi-device display offers several advantages",
        websiteTxt5:"Provide a good cross-device experience. Improve website accessibility. Increase user engagement and interactivity. good for search engine optimization",
        appName1:"Native APP",
        appTxt1:"Native apps are applications that are developed specifically for a particular platform, such as iOS or Android, using the platform's native programming languages, such as Objective-C/Swift for iOS or Java/Kotlin for Android. They are designed to fully leverage the platform's features and performance, providing the best user experience. Native apps typically require separate development and maintenance for each platform. Overall, native apps offer the best user experience but require platform-specific development.",
        appName2:"Hybrid APP",
        appTxt2:"A hybrid app is an application developed using web technologies such as HTML, CSS, and JavaScript, and then packaged as a native app using frameworks like React Native or Ionic. Hybrid apps can run on multiple platforms and reuse code, thereby saving development costs and time. However, they may not fully match the performance and functionality of native apps. In general, hybrid apps offer the advantage of cross-platform compatibility but may lack the performance of native apps.",
        appName3:"Web APP",
        appTxt3:"A Web app is an application developed using web technologies such as HTML, CSS, and JavaScript, and can run in a web browser. Web apps do not require installation; users can access them directly through a browser. They offer the advantages of cross-platform compatibility and real-time updates. However, they may be limited by network connectivity and browser capabilities. In general, Web apps have the advantages of cross-platform compatibility and real-time updates, but may have limitations in terms of functionality and performance.",
        wechatName1:"WeChat Mini APP",
        wechatTxt1:"WeChat Mini Program is a lightweight application that runs within the WeChat platform, allowing users to access and use various functionalities directly within the app without additional installations. It provides a wide range of features, including online shopping, social media, lifestyle services, and more. WeChat Mini Programs are developed using dedicated frameworks for Mini Programs, such as the WeChat Native Framework, Taro, or uni-app, which enable cross-platform compatibility and deliver good user experience and performance. In summary, WeChat Mini Program is a lightweight application running within the WeChat platform.",
        wechatName2:"WeChat Official Account",
        wechatTxt2:"WeChat Official Account, also known as WeChat Public Account, is an official account based on the WeChat platform where users can subscribe and receive messages, articles, images, videos, and more published by the account. They are typically owned and operated by businesses, organizations, celebrities, etc., and are used for brand promotion, information dissemination, customer interaction, and other purposes. WeChat Official Accounts can be categorized into Subscription Accounts and Service Accounts, with different functionalities and permissions. In general, WeChat Official Accounts are official accounts used for information dissemination and brand promotion.",
        wechatName3:"WeChat Work",
        wechatTxt3:"WeChat Work is an enterprise communication and collaboration platform designed specifically for internal use within businesses. It serves as an enterprise version of WeChat, providing features such as internal communication, organizational structure, workflow management, and application integration. Enterprise WeChat aims to enhance internal communication and collaboration within organizations. It also supports developing and customizing enterprise applications, allowing businesses to extend the functionality according to their specific needs. In summary, Enterprise WeChat is an internal communication and collaboration platform for businesses.",
        mall1:"Explore the unbounded shopping experience: the online mall takes you into a new world of e-commerce",
        mall111:"Product management",
        mall2:"Shopping cart function",
        mall3:"Collection",
        mall4:"Marketing activities",
        mall5:"Order management",
        mall6:"Login/Sign up",
        mall7:"Member Centre",
        mall8:"After-sale refund",
        mall9:"Rental Limited Time Offer",
        mall10:"Website+APP（iOS+Android）",
        mall11:"Year",
        mall12:"Purchase",
        mall13:"Purchase includes",
        mall14:"3 language versions (Traditional Chinese, Simplified Chinese, English)",
        mall15:"Including 10G of cloud space for one year and 50G of traffic per month",
        mall16:"Preferential price - HKD 2,900.00 will be charged from the second year (cloud space and maintenance fee - including the same service that is 10G space and monthly 50G traffic, system debugging service and app version update)",
        mall17:"From the third year, the regular price is HKD 5,800.00.(cloud space and maintenance fee - including the same service that is 10G space and monthly 50G traffic, system debugging service and app version update)",
        mall19:"Only access up to 4 payment methods, more than 4 will charge additional fees",
        it0:require('@/assets/img/IT_pic01-en.png'),
        it1:"Service scope covers both China and Hong Kong",
        itName1:"Data backup and protection",
        itTxt1:"Data backup and protection involve duplicating data to another location or storage media to prevent data loss, damage, or unauthorized access. It includes regularly backing up data, establishing backup strategies, encrypting data, monitoring and testing the effectiveness of backup systems. These measures ensure the reliability and security of data and provide preparedness for data disasters.",
        itName2:"Desktop maintenance",
        itTxt2:"Desktop maintenance is the activity of managing and supporting the computer desktop environment, including system monitoring, software installation, virus protection, data backup, and user support. It aims to provide a stable, secure, and efficient desktop working environment, ensuring smooth operations and data security for users.",
        itName3:"Computer Technical Support",
        itTxt3:"Computer technical support is a service that provides assistance and support in resolving computer issues, including software installation, troubleshooting, virus protection, network connectivity, hardware repairs, and user support, among others.",
        itTxt31:"Typically, computer technical support can be provided through various methods, including remote support, telephone support, on-site support, and online resources.",
        itTxt32:"In short, computer technical support is a service that provides solutions to computer and technology-related issues, helping users effectively use and maintain their systems. It is a crucial component in ensuring smooth operations and maximizing the benefits of computer technology.",
        itName4:"IT maintenance",
        itTxt4:"IT maintenance refers to a series of activities aimed at managing, maintaining, and supporting the information technology (IT) infrastructure and systems. It is intended to ensure the stable operation of IT resources and provide an efficient, secure, and reliable IT environment.",
        itTxt41:"The goal of IT maintenance is to ensure the smooth operation of IT systems and resources, minimizing the risk of system failures and data loss, and improving organizational productivity and efficiency. It is one of the critical functions of the IT department in businesses and organizations, contributing to the availability, security, and reliability of IT resources.",
        itName5:"Network management",
        itTxt5:"Network management refers to the activities of effectively managing and monitoring computer network infrastructure. It encompasses the management of network devices, connections, resources, and security aspects.",
        itTxt51:"The primary goals of network management are to ensure network availability, performance, and security to meet the needs of the organization and support business operations.",
        itTxt52:"Ensuring smooth and reliable network operation is a critical aspect of network management. It helps improve network performance, reduce the risk of failures, and provide a good user experience. Organizations and businesses can utilize network management to effectively manage and maintain their network infrastructure to support seamless business operations.",
        itName6:"Server management",
        itTxt6:"Server management refers to a series of activities that involve the effective management and monitoring of server hardware and software. It includes server configuration, installation, maintenance, and monitoring to ensure the smooth operation and efficient performance of servers.",
        itTxt61:"The main goal of server management is to ensure the availability, security, and performance of servers to meet the organization's needs and support business operations.",
        itTxt62:"Server management is a crucial aspect in ensuring smooth and reliable server operations. It helps improve server performance, minimize the risk of failures, and ensure the integrity and availability of data. Organizations and businesses can rely on effective server management to maintain the stability of their business systems and meet evolving requirements.",
        itReason:"Why choose CHINA SKY",
        itReason1:"Professional technology: Possess rich IT technical knowledge and experience.",
        itReason2:"Personalized service： Provide tailor-made solutions, focusing on the unique needs of customers.",
        itReason3:"Timely Response: Respond quickly and provide solutions to reduce downtime.",
        itReason4:"Comprehensive solution: provide a full range of IT support services, saving time and effort.",
        itReason5:"Trust and reputation: With a good reputation and reputation, we have established long-term cooperative relations with many customers.",
        rentBtn: "Rent",
    //
    look: "View Case",
    addStore01: "CHINA SKY TECHNOLOGY Anniversary Celebration",
    addStore02: "Online Store Rental",
    addStore03: "Explosive Price!!",
    addStore04: "RMB",
    addStore05: "Year",
    addStore06: "Includes Website + APP Store (iOS+Android)",
    addStore07: "Original Price: RMB 21,800.00/year",
    addStore08: "Rent Now",
    addStore09: "Online Store Rental Service",
    addStore10: "Our online store rental service eliminates the expensive cost of independent development, saves your development time, allows your business to start quickly, and the service includes websites and apps. It supports various types of product categories and provides a one-stop solution for creating your online store.",
    addStore11: "Membership System",
    addStore12: "Product System",
    addStore13: "Shopping Cart",
    addStore14: "Order System",
    addStore15: "Online Payment",
    addStore16: "Report Export Function",
    addStore17: "Discount Function",
    addStore18: "Points Deduction for Cash Function",
    addStore19: "Supports Traditional Chinese, Simplified Chinese, and English",
    addStore20: "1 year of cloud storage space (10GB) and 50GB monthly traffic",
    addStore21: require('@/assets/img/ad-en.png'),
},
video:{
        url:require('@/assets/img/video-en.mp4'),
        subtitles1:"Nowadays, the evolutionary speed of the online world is amazing. To make customers feel more friendly and convenient, a complete IT system and network platform is a must.",
        subtitles2:"China Sky Technology System Limited was established at Hong Kong in 2004 and Shenzhen branch was opened in the same year.",
        subtitles3:"We have been providing IT outsourcing services to companies in Hong Kong and the Mainland, including fixed inspections, network management, network security, and VPN solutions.",
        subtitles4:"Since we have professional technicians in both mainland of China and Hong Kong, we can provide the most immediate and appropriate support to customers regardless of the IT problems encountered in Hong Kong or in the Mainland.",
        subtitles5:"In recent years, network marketing has provided enterprises with brand new business opportunities. The related programs and services of social networking platforms have become powerful tools for companies. ",
        subtitles6:"China Sky Technology has mastered comprehensive related technologies for each project from design, development and online promotion. It also has a strong development and marketing team to provide customers with various types of marketing strategies and corresponding development services. We help customers in the most cost efficient way.",
        subtitles7:"In order to cooperate with the development of the Dawan District and the Belt and Road, both domestic and foreign companies need to further optimize business processes and costs in order to expand business opportunities. ",
        subtitles8:"We have the experience of network consulting in China and abroad, ",
        subtitles9:"and can design the most suitable network marketing solutions for our customers and make their business more successful.",
},
    net:{
        servesBigTitle0:"Our Service",
        servesBigTitle1:"Network maintenance",
        servesTitle1:"Network Monitoring",
        servesContent1:"Monitoring network traffic, connectivity status, and performance to ensure the network operates smoothly.",
        servesTitle2:"Security Checks",
        servesContent2:"Regular security assessments, including firewall configurations, intrusion detection systems, and vulnerability assessments, to prevent unauthorized access or attacks.",
        servesTitle3:"Data Backup and Recovery",
        servesContent3:"Establishing regular network data backup plans to ensure crucial data can be swiftly recovered when needed.",
        servesTitle4:"Network Equipment Updates",
        servesContent4:"Regular updates of router, switches, and other network device software, along with hardware performance checks, to ensure efficient network operations.",
        servesTitle5:"Network Performance Optimization",
        servesContent5:"Optimizing network settings, adjusting configurations of routers and switches to ensure efficient data transmission and low latency.",
        servesTitle6:"User Account Management",
        servesContent6:"Managing user accounts, including creating, deleting, and modifying account permissions, ensuring authorized users can access network resources.",
        servesTitle7:"Emergency Response Plans",
        servesContent7:"Developing plans to respond to network failures and security incidents, ensuring prompt responses and resolutions in case of network issues.",
        servesTitle8:"Training and Awareness",
        servesContent8:"Providing security training for users to enhance their awareness, reducing the risks of network threats.",
        servesBigTitle2:"Computer maintenance",
        servesTitle21:"Hardware Inspection",
        servesContent21:"Regular checks of computer hardware components to ensure proper cooling and performance.",
        servesTitle22:"Software Updates and Security Patches",
        servesContent22:"Installation of the latest operating system, application software, and antivirus updates, along with timely installation of security patches.",
        servesTitle23:"Virus and Malware Scans",
        servesContent23:"Regular scans for viruses and malware to ensure system security, removing any potential threats.",
        servesTitle24:"Data Backup and Recovery",
        servesContent24:"Establishing regular data backup plans to ensure important data can be quickly recovered when needed.",
        servesTitle25:"Performance Optimization",
        servesContent25:"Optimizing system settings, enhancing computer performance, and clearing unnecessary files and applications to free up disk space.",
        servesTitle26:"Hardware Replacement and Upgrades",
        servesContent26:"Periodic evaluation of hardware performance, upgrading or replacing hardware as needed to keep up with business requirements.",
        servesTitle27:"User Support and Issue Resolution",
        servesContent27:"Providing user support services, resolving problems users encounter while using computers, ensuring efficient system usage.",
        reason0:"Why need us",
        reason1:"computer systems are the backbone of business operations, handling data storage, processing, and communication functions. Regular maintenance ensures stable system performance, reducing the chances of breakdowns and ensuring business continuity. ",
        reason2:"With the increasing cybersecurity threats, maintenance services can identify system vulnerabilities, install the latest security patches, and enhance the system's resistance against attacks, safeguarding critical data from breaches. ",
        reason3:"Regular maintenance can prolong the lifespan of computer equipment, thereby reducing operational costs for businesses. In summary, computer maintenance and servicing are vital investments for businesses to ensure smooth operations, data security, and cost-effectiveness.",
        set0:"Service package",
        set1:"Remote Service",
        set2:"Express door-to-door service (per trip)",
        set3:"Monthly service",
        set4:"Annual fee service",
        set5:"Remotely",
        set6:"Call up",
        set7:"Go to the scene",
        set8:"Periodic inspection",
        set9:"Unlimited times",
        set10:"1 time",
        set11:"1 time/month",
    },

}